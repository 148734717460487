<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="_cancel">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0"
     >
      <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <!-- This element is to trick the browser into centering the modal contents. -->
     <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
     >
      <div
       class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
       <div class="sm:flex sm:items-start">
        <div
         class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 sm:mx-0 sm:h-10 sm:w-10"
        >
         <font-awesome-icon icon="share" class="h-5 w-5 text-gray-100" aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
         <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 flex">
          <p class="self-center">{{ title }}</p>
          <input
           type="text"
           name="report"
           v-model="nickname"
           class="ml-2 sm:text-sm border-gray-300 rounded-md h-8"
           :placeholder="$t('pbxReport.nicknamePlaceholder')"
          />
         </DialogTitle>
         <div class="my-2 text-sm text-gray-500">
          <p class="text-sm text-gray-500">{{ message }}</p>
          <ul role="list" class="list-disc pl-5">
           <li class="capitalize">
            {{
             this.$route.name === "ExpertStatistics"
              ? $t("expertStatisticsNavigation.origins")
              : this.$route.name === "ExpertStatisticsAnsweredUnanswered"
              ? $t("expertStatisticsNavigation.answeredUnansweredCalls")
              : this.$route.name === "ExpertStatisticsOriginDetail"
              ? $t("expertStatisticsNavigation.originsTop10")
              : this.$route.name === "ExpertStatisticsQueueDetail"
              ? "Queue Details"
              : "Custom"
            }}
           </li>
           <li v-if="title === $t('pbxReport.shareScheduleTitle')" class="capitalize">
            <select
             id="location"
             name="location"
             class="mt-1 block py-1 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
             v-model="this.cron"
            >
             <option value="0 4 * * *">{{ $t("dashboards.daily") }}</option>
             <option value="0 4 * * 1">{{ $t("dashboards.weekly") }}</option>
             <option value="0 4 1 * *">{{ $t("dashboards.monthly") }}</option>
            </select>
           </li>
           <li v-else class="capitalize">
            <span v-show="timeOption"> {{ timeOption }}, </span>
            {{ parseDateRange(periodSelector) }}
            <span v-show="time">, {{ parseTimeRange(time) }}</span>
           </li>
           <li class="capitalize">
            {{
             elementOption === "queue" ? $t("sidebar.specialNavigation.callqueue") : elementOption
            }}
            <span class="mr-1" v-for="(selection, index) in this.pbxElements" :key="selection"
             >{{ selection }}{{ index === selections.length - 1 ? "" : "," }}</span
            >
           </li>
          </ul>
          <p class="text-sm text-gray-500 pt-2">
           {{ $t("pbxReport.content") }}
          </p>
         </div>
         <form class="mt-0 sm:flex sm:max-w-md lg:mt-0" @submit.prevent="pushToEmails(email)">
          <div>
           <label for="email-address" class="sr-only">Email address</label>
           <div class="relative">
            <input
             v-model="email"
             type="email"
             name="email-address"
             id="email-address"
             autocomplete="email"
             required
             :class="[
              this.invalidEmail
               ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
               : 'border-gray-300 placeholder-gray-400 ',
              'shadow-sm block w-full sm:text-sm rounded-md',
             ]"
             placeholder="you@example.com"
            />
            <div
             v-if="this.invalidEmail"
             class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
             <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
           </div>
           <p v-if="this.invalidEmail" class="text-sm text-red-600" id="email-error">
            {{
             !this.emailRegexError
              ? $t("pbxReport.emailRegexError")
              : $t("pbxReport.emailAlreadyExistError")
            }}
           </p>
          </div>
          <div class="mt-0 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
           <button
            type="submit"
            class="capitalize w-full body__header--button border border-transparent rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium text-white"
           >
            {{ $t("add") }}
           </button>
          </div>
         </form>
        </div>
       </div>
       <div class="py-1" v-show="emails.length > 0">
        <div v-for="email in emails" :key="email" class="inline-flex mb-1">
         <div class="mx-1">
          <span
           class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-600"
          >
           {{ email }}
           <button
            @click="removeEmailFromList(email)"
            type="button"
            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-700 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
           >
            <span class="sr-only">Remove option</span>
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
             <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
           </button>
          </span>
         </div>
        </div>
       </div>
       <div class="pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
         type="button"
         class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 encom_primary encom_primary_hover text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
         @click="_confirm"
        >
         {{ $t("ticket.send") }}
        </button>
        <button
         type="button"
         class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
         @click="_cancel"
         ref="cancelButtonRef"
        >
         {{ $t("ticket.cancel") }}
        </button>
       </div>
      </div>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import PopupModal from "../PopupModal.vue";
import axios from "axios";
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

const timezone = localStorage.getItem("hostTimeZone");

export default {
 props: [
  "elementOption",
  "parseDateRange",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   cron: "0 4 * * *",
   dataset: "",
   emails: [],
   email: "",
   invalidEmail: false,
   emailRegexError: false,
   emailAlreadyExistError: false,
   message: undefined, // Main text content
   nickname: "",
   title: undefined,
   hour: "",
   timezone,
   subMessage: undefined, // text content before input
  };
 },
 components: {
  ExclamationCircleIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
 },
 methods: {
  setUpDataSet() {
   if (this.$route.name === "ExpertStatistics") {
    this.dataset = "origins";
   } else if (this.$route.name === "ExpertStatisticsOriginDetail") {
    this.dataset = "originDetails";
   } else if (this.$route.name === "ExpertStatisticsAnsweredUnanswered") {
    this.dataset = "answered";
   } else if (
    this.$route.name === "ExpertStatisticsAnsweredUnanswered" &&
    this.selectHostContext === "0"
   ) {
    this.dataset = "answeredInCallQueues";
   } else if (this.$route.name === "ExpertStatisticsQueueDetail") {
    this.dataset = "queueDetail";
   }
   this.setUpParams();
  },
  setUpParams() {
   let elements = this.pbxElements.toString();
   let hour = undefined;
   if (this.pbxTimeSelected) {
    hour = this.pbxTimeSelected[0] + ":" + this.pbxTimeSelected[1];
   } else {
    hour = null;
   }

   let parameters = {
    pbx: this.selectHostName,
    type: "queue",
    elements: elements,
    time: hour,
    timezone: this.timezone,
    period: this.periodSelector,
    context: null,
    dataSet: this.dataset,
   };
   this.postReport(parameters, "thank you");
  },
  postReport(parameters, msg) {
   const params = JSON.stringify(parameters);
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/presentations`,
    data: {
     nickname: this.nickname,
     parameters: params,
     email_addresses: this.emails,
     cron: this.cron,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("pbxReport.successMsg"));
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  show(opts = {}) {
   this.title = opts.title;
   this.message = opts.message;
   this.okButton = opts.okButton;
   if (opts.cancelButton) {
    this.cancelButton = opts.cancelButton;
   }
   if (opts.action) {
    this.action = opts.action;
   }
   // Once we set our config, we tell the popup modal to open
   this.$refs.popup.open();
   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  _confirm() {
   try {
    this.setUpDataSet();
    this.$refs.popup.close();
    this.resolvePromise(true);
   } catch (error) {
    console.error(error);
   }
  },

  _cancel() {
   this.$refs.popup.close();
   this.resolvePromise(false);
   // Or you can throw an error
   // this.rejectPromise(new Error('User cancelled the dialogue'))
  },
  pushToEmails(email) {
   this.invalidEmail = false;

   if (this.validateEmail(email) && !this.emails.includes(email)) {
    this.emails.push(email);
    this.email = "";
   } else {
    this.invalidEmail = true;
    this.validateEmail(email)
     ? (this.emailRegexError = true)
     : (this.emailAlreadyExistError = true);
   }
  },
  removeEmailFromList(email) {
   let index = this.emails.indexOf(email);
   this.emails.splice(index, 1);
  },
  validateEmail(mail) {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
  },
 },
 computed: {
  ...mapGetters(["pbxElements", "pbxTimeSelected", "pbxTimeSelectedEnd"]),
 },
 watch: {
  timeOption: function (val) {
   if (val === this.$t("dashboards.daily")) {
    this.cron = "0 4 * * *";
   } else if (val === this.$t("dashboards.weekly")) {
    this.cron = "0 4 * * 1";
   } else {
    this.cron = "0 4 1 * *";
   }
  },
 },
};
</script>

<style></style>
